import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Helmet } from 'react-helmet';

import RealTimeBackendCard from '#components/page-content/backend-page/RealTimeBackendCard';
import SessionCard from '#components/page-content/backend-page/SessionCard';

const PageTitle = 'Profile';

const ProfilePage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <SessionCard />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <RealTimeBackendCard />
        </Grid>
      </Grid>
    </>
  );
};

export default ProfilePage;
